import React,{useState,useEffect} from "react";
import {useLocation } from "react-router-dom";
import axios from "axios";
import Certificate from './certificate';
import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default function  Quizresult() {
  const [user, setUser] = useState({});
  const inputRef = useRef(null);
  const [list, setList] = useState([]);
  const [imgPath, setImgPath] = useState("");
  const [currentdata, setCurrentdata] = useState(0);
  const [date, setDate] = useState(new Date());
  const RandomNumber = Math.floor(Math.random() * 1000000000) + 1;
  const search = useLocation().search;
  const [showresult, setShowresult] = useState(0);
  const journals_id = new URLSearchParams(search).get("journals_id");
   const id = new URLSearchParams(search).get("id");

  const [submit,setSubmit]=useState({
    name:'',
  });
 
  const config = {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent",
      secret_key: "sk_e8cTjtTm7B4XHOctkK5ZYw0Nhgmef12g85I+EL21kgg=",
      publish_key: "pk_JKDdoaiN9ch4w9b+FkKUWWbaycUjegzhlQP6q4XZo1d5",
    },
  };
  useEffect(() => {
    printDocument()
  });

  const printDocument = async () => {
    await html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0, 0, 0);
      pdf.save("download.pdf");
      const formData = new FormData();
      formData.append("imgdata", imgData);
      formData.append("journals_id", journals_id);
      formData.append("user_id", id);
      axios
        .post(
          `https://app.stopny.com/api/generate_certificate_post`,formData,config
        )
        .then((result) => {
          console.log(result.data.body,"======results====");
        })
    })
  };

  const bodydata = {
    journals_id: journals_id,
    user_id: id,
  };
  const Getquestion = async () => {
    await axios
      .post(
        `https://app.stopny.com/api/questions_listing`,
        bodydata,
        config
      )
      .then((result) => {
        setList(result.data.body[currentdata].journals_name);
        // console.log(result.data,"=========journalssss===api1");
      });
  };

  const handleResult = async () => {
    await axios.get(`https://app.stopny.com/api/getuser_details?id=${id}`,config).then((result)=>{
        // console.log(result.data,"--=========result================================");
         setSubmit (result.data.body); 
        if(submit.name){
          setShowresult(true)
        }    
    })
  };

  useEffect(() => {
    // handleResult();
    Getquestion();
    
  });

  return (
    <div>
    {showresult? (
        <div className="certi" >
           <div className="container_box">
           <section
            id="my-node"
            ref={inputRef}
            className="certificate"
            style={{
              // backgroundColor: "#f5f5f5",
              // width: "100%",
              // minHeight: "100mm",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            >
            <div className="container"> 
            <div className="main_div d-flex justify-content-center align-items-center">
          <img src="../assets/image/back.jpg" alt="" className="w-100" />
          <div className="inner_text text-center">
            <div className="img_text">
              <p>This certificate is proudly presented to:</p>
              <p>
                <input type="text" value={submit.name} />
              </p>
            </div>
            <div className="img_text1">
              <p>
                as witness and certified by the seal for completing the <br />
                <input type="text" value={list} /> Modules Module stopnyl 2022-2023
              </p>
            </div>
          </div>
       
            </div>
            </div>
            </section>
            </div>
      </div>
    ) : (
    <div className="app py-4">
    <section className="score_section">
      <div className="container">
        
        <div className="row justify-content-center">
          <div className="col-md-4 ">
            <div className="card py-md-3 py-5 ">
              <h2>WoW !</h2>
              <h4>
                Congrats... {submit.name} <br/>
               You Complete your quiz. 
              </h4>
              <div className="btns">
                <button onClick={handleResult}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    )}
    </div>
  );
  
}
