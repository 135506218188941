import React from 'react';

function NotFound (){
  return (
    <>
      <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="page-error">
              <div className="page-inner">
                <h1>Not Found</h1>
                <div className="page-description">
                  The page you were looking for could not be found.
                </div>
                <div className="page-search">
                  <form>
                   
                  </form>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
    )
 
}

export default NotFound;
