
// import NotFound from "../components/notfound";
import { useRoutes} from "react-router-dom";
import Home from "../components/home";
import Certificate from "../components/certificate";
function AppRoutes() {
    const routes = useRoutes(
      [
        // {path:'/home',element:<NotFound/>},
         {path:'/quiz',element:<Home/>},
         {path:'/certificate',element:<Certificate/>},
       

        
      ]
    )
    return routes;
  }

  export default AppRoutes;