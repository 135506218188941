import React from "react";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Quizresult from "./quizresult";
import Swal from 'sweetalert2';
import NotFound from './notfound'

export default function Home() {
  const search = useLocation().search;
  const user_id = new URLSearchParams(search).get("user_id");
  const journals_id = new URLSearchParams(search).get("journals_id");
  

  const [question, setQuestion] = useState([]);
  const [showresult, setShowresult] = useState(0);
  const [currentquestion, setCurrentquestion] = useState(0);
  
  let bodydata = {
    journals_id: journals_id,
  };
  const config = {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent",
      secret_key: "sk_e8cTjtTm7B4XHOctkK5ZYw0Nhgmef12g85I+EL21kgg=",
      publish_key: "pk_JKDdoaiN9ch4w9b+FkKUWWbaycUjegzhlQP6q4XZo1d5",
    },
  };

  const Getquestion = async () => {
    await axios
      .post(
        `https://app.stopny.com/api/questions_listing`,
        bodydata,
        config
      )
      .then((result) => {
        setQuestion(result.data.body);
      
      });
  };
  
  useEffect(() => {
    Getquestion();
  }, []);

  const Handlequestion = async (correct_option) =>
  {
    if (correct_option.trim() == question[currentquestion].correct_option.trim()) {
      Swal.fire('Continue', 'CorrectAnswer')
      const nextQuestion = currentquestion + 1;
      console.log(nextQuestion);
      if (question.length > nextQuestion) {
        setCurrentquestion(nextQuestion);
      } else {
        setShowresult(true);
      }
    } else {
      Swal.fire('Try Again', 'InCorrectAnswer')
      // setShowresult(true)
    }
  };

  const handleplayAgain = () => {
    setCurrentquestion(0);
    setShowresult(false);
  };
  
  return (
    <>
      <div>
        {journals_id ? (
          <div className="app py-4">
            {showresult ? (
              <Quizresult handleplayAgain={handleplayAgain} />
            ) : (
              <>
                <form>
                  <div className="container-md container-fluid mt-5">
                    <div className="mb-3 row justify-content-center mt-5">

                      {question.length > 0 && (
                        <div className="col-md-8">
                          <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                            <div className="col-md-8">
                              <h1 className="text-white">Question</h1>
                            </div>
                            <label
                              className="list-group-item box_round q_box rounded-3 py-md-3 py-2"
                              htmlFor={"listGroupCheckableRadios"}
                            >
                              <p>{question[currentquestion].question}</p>
                            </label>
                            <h3 className="text-white px-3">Answer</h3>
                            <div className="row">
                              {(question[currentquestion] && question[currentquestion].options) &&
                            
                                JSON.parse(question[currentquestion].options).map((e, i) =>
                                {
                                  // console.log(e, "=here")
                                  return (
                                    <>
                                      <div className="col-lg-6 col-12" key={i}>
                                        <div className="list-group list-group-checkable d-grid gap-2  py-1 border-0 w-auto">
                                          <label
                                            className="list-group-item box_round rounded-3 py-md-3 py-2"
                                            htmlFor={"listGroupCheckableRadios" + i}
                                          >
                                            <h3 className="mb-0">{i + 1}.</h3>
                                            <p>{e}</p>
                                          </label>
                                          <input
                                            className="list-group-item-check d-none"
                                            type="radio"
                                            name={"listGroupCheckableRadios" + i}
                                            id={"listGroupCheckableRadios" + i}
                                            onClick={() =>
                                            {
                                              Handlequestion(e);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        ) : (
        <NotFound/>
        )}
        
      </div>
     
    </>
  );
}
